import { FunctionComponent, useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Form, { EInputType } from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';
import Spinner from '@/components/Spinner';

import BannerImage from '@/globals/images/hero_emailStep.png';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';
import { LocaleContext } from '@/providers/LocaleProvider';

import Layout from './Layout';
import Styles from './styles.module.scss';

const Email: FunctionComponent = () => {
  const {
    customImages: { imageFormEmail },
    identifier,
  } = useContext(ECommerceContext);
  const { getTranslation } = useContext(LocaleContext);

  const [consentDiagnosisEmail, setConsentDiagnosisEmail] = useState(false);
  const [consentPersonalDataEndro, setConsentPersonalDataEndro] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [sending, send] = useState<boolean>(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFocus(true);
    }, 500);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <Layout title='form.personalizationStep.email.title' image={imageFormEmail ? `data:image/png;base64,${imageFormEmail}` : BannerImage}>
      <div className={Styles.personnalisationStep__description}>
        <LocaleText isHtml={true}>form.personalizationStep.email.description</LocaleText>
      </div>
      <Form.Input
        focus={focus}
        type={EInputType.EMAIL}
        name='customer_email'
        placeholder={getTranslation('form.personalizationStep.email.placeholder')}
        required
        onChange={(value) => {
          const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
          setEmailValid(regex.test(value.toString()) === true ? true : false);
        }}
      />

      <div className={Styles.personnalisationStep__footer}>
        {identifier === 'endro_cosmetiques' && (
          <div className={Styles.personnalisationStep__checkboxContainer}>
            <Form.Checkbox
              value={consentPersonalDataEndro}
              name='personal_data_endro'
              onChange={(value) => {
                setConsentPersonalDataEndro(value ? true : false);
              }}
            />
            <LocaleText>form.personalizationStep.email.endro_checkbox</LocaleText>
          </div>
        )}

        <div className={Styles.personnalisationStep__checkboxContainer}>
          <Form.Checkbox
            value={consentDiagnosisEmail}
            name='diagnosis_email'
            onChange={(value) => {
              setConsentDiagnosisEmail(value ? true : false);
            }}
          />
          <LocaleText>form.personalizationStep.email.receive_diagnosis</LocaleText>
        </div>

        <div className={Styles.personnalisationStep__footerCTA}>
          <Button
            type='submit'
            disabled={!emailValid}
            rounded
            className={`${sending ? Styles.sending : ''}`}
            onClick={() => {
              if (!sending) {
                send(true);
              }
            }}
          >
            <LocaleText>form.personalizationStep.submit</LocaleText>
            {sending ? <Spinner className={Styles.personnalisationStep__footerCTA__spinner} /> : undefined}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Email;
