import { FunctionComponent, useEffect, useState } from 'react';

import Slider, { Settings } from 'react-slick';

import RecommendationCard from '@/components/RecommendationCard';

import Inner from '@/components/Inner';

import { TSubRoutine } from './interfaces';
import Styles from './styles.module.scss';

const Routine: FunctionComponent<TSubRoutine> = ({ index, rIndex, parentIndex, routine, isCurrentStep, selectedProducts, onCardSelect }) => {
  const [activeCards, setActiveCards] = useState<number[]>(
    routine.proposed_products.reduce((acc, product, index) => {
      selectedProducts.forEach((selectedProduct) => {
        if (product.externalId === selectedProduct.externalId) {
          acc.push(index);
        }
      });
      return acc;
    }, [] as number[])
  );

  const onProductSelect = (id: number, externalId: string, active: boolean, routineIndex: number, pIndex: number) => {
    setActiveCards((prevActiveCards) => {
      if (active) {
        return [...prevActiveCards, pIndex];
      } else {
        return prevActiveCards.filter((index) => index !== pIndex);
      }
    });

    onCardSelect(id, externalId, active, routineIndex);
  };

  const sliderSettings: Settings = {
    arrows: false,
    infinite: false,
    variableWidth: true,
  };

  useEffect(() => {
    const activeIndices = routine.proposed_products.reduce((acc, product, index) => {
      selectedProducts.forEach((selectedProduct) => {
        if (product.externalId === selectedProduct.externalId) {
          acc.push(index);
        }
      });
      return acc;
    }, [] as number[]);
    setActiveCards(activeIndices);
  }, [selectedProducts, routine.proposed_products]);

  return (
    <div
      key={'recommendationSubroutine-' + parentIndex + '-sub-' + rIndex}
      className={`${Styles.recommendationSubroutine} ${isCurrentStep ? Styles['recommendationSubroutine--active'] : ''}`}
    >
      <Inner className={Styles.recommendationSubroutine__heading}>
        <p className={Styles.recommendationSubroutine__title}>
          {parentIndex + 1}.{rIndex + 1} - {routine.title}
        </p>
      </Inner>
      <div className={Styles.recommendationSubroutine__body}>
        <Inner type='full'>
          <Slider className={Styles.recommendationSubroutine__slider} {...sliderSettings}>
            {routine?.proposed_products?.map((product: any, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isVisible={isCurrentStep}
                isActive={activeCards.includes(pIndex)}
                onSelect={(id, externalId, active, i) => onProductSelect(id, externalId, active, index, pIndex)}
              />
            ))}
          </Slider>
        </Inner>
      </div>
    </div>
  );
};

export default Routine;
