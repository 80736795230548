import { useContext } from 'react';

import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';

import BannerImage from '@/globals/images/hero_genderStep.png';

import { ECustomerGender } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';

const Sex: React.FC<TPersonalizationStepForm> = ({ formRef }) => {
  const {
    customImages: { imageFormGenre },
  } = useContext(ECommerceContext);

  return (
    <Layout title='form.personalizationStep.sex.title' image={imageFormGenre ? `data:image/png;base64,${imageFormGenre}` : BannerImage}>
      {Object.values(ECustomerGender).map((value) => (
        <Form.RadioButton
          key={value}
          name='gender'
          value={value}
          onChanged={() => {
            formRef.current!.next();
          }}
        >
          <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
      ))}
    </Layout>
  );
};

export default Sex;
