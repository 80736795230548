import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import Button from "@/components/Button";
import Icon from "@/components/Icon";
import Inner from "@/components/Inner";
import LocaleText from "@/components/Intl/LocaleText";
import Routine from "@/components/Routine";
// import NoRecommendation from "@/globals/images/no_recommendation.svg";
import Title from "@/components/Title";

import recommendationImage from "@/globals/images/hero_recommendationStep.jpg";

import { LocaleContext } from "@/providers/LocaleProvider";

import { ProcessContext } from "..";

import { TProductInCart, TRecommendationStep } from "./interfaces";
import Styles from "./styles.module.scss";
import Spinner from "@/components/Spinner";
import PopinError from "@/components/PopinError";

const RecommendationStep: FunctionComponent<TRecommendationStep> = ({
  products,
  prev,
  onSubmit,
}) => {
  const { recommendations, goToHome } = useContext(ProcessContext);
  const localeContext = useContext(LocaleContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [routinesLimit, setRoutinesLimit] = useState(4);
  const [routines, setRoutines] = useState<Array<TProductInCart | false>>([]);
  const [productList, setProductList] = useState(products ?? []);
  const [errorRecoNotFoundPopin, setErrorRecoNotFoundPopin] = useState(false);

  const onCardSelect = useCallback(
    async (id: number, externalId: string, active: boolean) => {
      const tempList = [...routines];
      if (active) {
        tempList.push({ id, externalId });
        setRoutines(tempList);
      } else {
        tempList.splice(
          tempList.findIndex(
            (product) => product && product?.externalId === externalId
          ),
          1,
          false
        );
        setRoutines(tempList);
      }
    },
    [routines]
  );

  const onRoutineReset = useCallback(
    (routineIndex: number) => {
      const tempList = routines.slice();

      tempList.splice(routineIndex, 1, false);
      setRoutines(tempList);
    },
    [routines]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setErrorRecoNotFoundPopin(
      recommendations === null || recommendations.routines.length === 0
    );
    let routines: Array<TProductInCart | false> = [];
    if (recommendations?.routines) {
      recommendations.routines.forEach((routine) => {
        const p = routine.proposed_products.find(({ externalId }) =>
          products?.map((product) => product.externalId).includes(externalId)
        );
        routines.push(p ? { id: p.id, externalId: p.externalId } : false);
        routine.sub_routines.forEach((sub) => {
          const p = sub.proposed_products.find(({ externalId }) =>
            products?.map((product) => product.externalId).includes(externalId)
          );
          routines.push(p ? { id: p.id, externalId: p.externalId } : false);
        });
      });
    }
    setRoutines(routines);
  }, [products, recommendations, goToHome, localeContext.locale]);

  useEffect(() => {
    setProductList(
      routines
        .slice()
        .filter((p) => !!p)
        .map((p) => p as TProductInCart)
    );
  }, [routines]);

  const recommendationsErrorPopin = (
    <PopinError
      active={errorRecoNotFoundPopin}
      onClose={goToHome}
      title={"global.popinErrorRecoNotFound.title"}
      description={"global.popinErrorRecoNotFound.description"}
      backHome={"global.popinErrorRecoNotFound.backHome"}
    />
  );

  let indexRoutine = 0;

  return recommendations?.routines ? (
    <div className={Styles.recommendationStep}>
      <div className={Styles.recommendationStep__back}>
        <div className={Styles.recommendationStep__back__link} onClick={prev}>
          <Icon
            icon="arrow-left"
            className={Styles.recommendationStep__back__icon}
          />
          <LocaleText>form.layout.back</LocaleText>
        </div>
      </div>
      <div className={Styles.recommendationStep__visual}>
        <img
          className={Styles.recommendationStep__visual__Image}
          src={recommendationImage}
          alt="Recommendations"
        />
      </div>
      <div className={Styles.recommendationStep__contentContainer}>
        <Inner type="md" className={Styles.recommendationStep__heading}>
          <Title className={Styles.recommendationStep__title} type="h2">
            <LocaleText>page.recommendationStep.title</LocaleText>
          </Title>
          <Title className={Styles.recommendationStep__subtitle} type="h3">
            <LocaleText>page.recommendationStep.subtitle</LocaleText>
          </Title>
        </Inner>

        <div className={Styles.recommendationStep__body}>
          {recommendations.routines
            .sort((a, b) => a.position - b.position)
            .slice(0, routinesLimit)
            .map((routine, rIndex: number) => {
              let el = (
                <Routine
                  key={"recommendationRoutine-" + rIndex}
                  index={indexRoutine}
                  rIndex={rIndex}
                  routine={routine}
                  active={routines?.[indexRoutine]}
                  recommendationRoutines={routines}
                  isCurrentStep={currentStep === rIndex ? true : false}
                  onStepChange={setCurrentStep}
                  onCardSelect={onCardSelect}
                  onRoutineReset={onRoutineReset}
                />
              );
              indexRoutine = indexRoutine + 1 + routine.sub_routines.length;
              return el;
            })}
          {recommendations.routines.length > routinesLimit ? (
            <div>
              <Button
                outline
                rounded
                onClick={() => setRoutinesLimit(1000)}
                className={Styles.recommendationStep__more__button}
              >
                <LocaleText>page.recommendationStep.more</LocaleText>
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
        <Inner type="md" className={Styles.recommendationStep__footer}>
          {productList.length === 0 && (
            <div className={Styles.recommendationStep__footer__productCounter}>
              <LocaleText>page.recommendationStep.noSelectedProduct</LocaleText>
            </div>
          )}
          {productList.length > 0 && (
            <div className={Styles.recommendationStep__footer__productCounter}>
              <p>{productList.length}&nbsp;</p>
              <LocaleText plural={productList.length > 1}>
                page.recommendationStep.selectedProduct
              </LocaleText>
            </div>
          )}
          <Button
            rounded
            disabled={productList.length === 0}
            onClick={() => onSubmit(productList)}
            className={Styles.recommendationStep__footer__button}
          >
            <LocaleText>page.recommendationStep.submit</LocaleText>
          </Button>
        </Inner>
      </div>
      {recommendationsErrorPopin}
    </div>
  ) : (
    //! Do not use, to be deleted later if it is confirmed that it will not be used
    // <div className={Styles.recommendationStep}>
    //   <div className={Styles.recommendationStep__noRecommendation}>
    //     <div
    //       className={
    //         Styles.recommendationStep__noRecommendation__titleContainer
    //       }
    //     >
    //       <Title
    //         className={Styles.recommendationStep__noRecommendation__title}
    //         type="h2"
    //       >
    //         <LocaleText>
    //           page.recommendationStep.noRecommendation.title
    //         </LocaleText>
    //       </Title>
    //       <LocaleText
    //         className={Styles.recommendationStep__noRecommendation__subtitle}
    //       >
    //         page.recommendationStep.noRecommendation.subtitle
    //       </LocaleText>
    //     </div>
    //     <div className={Styles.recommendationStep__noRecommendation__content}>
    //       <img src={NoRecommendation} alt="" />
    //       <LocaleText
    //         className={Styles.recommendationStep__noRecommendation__message}
    //       >
    //         page.recommendationStep.noRecommendation.message
    //       </LocaleText>
    //       <div
    //         className={Styles.recommendationStep__noRecommendation__backHome}
    //         onClick={goToHome}
    //       >
    //         <LocaleText>
    //           page.recommendationStep.noRecommendation.backHome
    //         </LocaleText>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={Styles.recommendationStep}>
      <Spinner className={Styles.recommendationStep__loading} />
      {recommendationsErrorPopin}
    </div>
  );
};

export default RecommendationStep;
