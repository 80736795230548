import { FunctionComponent, useContext, useState } from 'react';

import Button from '@/components/Button';
import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';
import Spinner from '@/components/Spinner';

import BannerImage from '@/globals/images/hero_priorityStep.png';

import { ECustomerSkinProblem } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';
import Styles from './styles.module.scss';

const HealthTarget: FunctionComponent<TPersonalizationStepForm> = ({ formRef }) => {
  const { hideEmailRequest } = useContext(ECommerceContext);
  const [sending, send] = useState<boolean>(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  return (
    <Layout title='form.personalizationStep.healthTarget.title' image={BannerImage}>
      {Object.values(ECustomerSkinProblem).map((value) => (
        <Form.RadioButton
          key={value}
          name='problem'
          value={value}
          onChanged={() => {
            if (hideEmailRequest) setSubmitButtonDisabled(false);
            else formRef.current!.next();
          }}
        >
          <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
      ))}
      {hideEmailRequest === true && (
        <Button
          type='submit'
          disabled={submitButtonDisabled}
          className={`${sending ? Styles.sending : ''}`}
          rounded
          onClick={() => {
            if (!sending) {
              send(true);
            }
          }}
        >
          <LocaleText>form.personalizationStep.submit</LocaleText>
          {sending ? <Spinner className={Styles['personnalisationStep__footerCTA__spinner']} /> : undefined}
        </Button>
      )}
    </Layout>
  );
};

export default HealthTarget;
