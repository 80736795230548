import { FunctionComponent, useContext } from 'react';

import { PRICE_FORMAT_OPTIONS } from '@/helpers/constants';
import { formatPriceForDisplay } from '@/helpers/price';

import { LocaleContext } from '@/providers/LocaleProvider';

import { TCartCard } from './interfaces';
import Styles from './styles.module.scss';

const CartCard: FunctionComponent<TCartCard> = ({ brand, className, fullWidth, id, image_url, name, price, reducedPrice }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <div className={`${Styles.cartCardContainer} ${className ? ` ${className}` : ''}`} data-id={id}>
      <div className={`${Styles.cartCard} ${fullWidth ? ` ${Styles['cartCard--fullWidth']}` : ''}`}>
        <div className={Styles.cartCard__body}>
          <div className={Styles.cartCard__visual}>
            <img src={image_url} className={Styles['cartCard__visual-image']} alt='card visual' />
          </div>
          <div className={Styles.cartCard__content}>
            <p className={Styles.cartCard__title}>{name}</p>
            <p className={Styles.cartCard__brand}>{brand?.name}</p>
          </div>

          {price !== null && (
            <div className={Styles.cartCard__priceContainer}>
              <div>
                <p className={Styles.cartCard__finalPrice}>
                  {reducedPrice !== null
                    ? formatPriceForDisplay((reducedPrice / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))
                    : formatPriceForDisplay((price / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))}
                </p>
                {reducedPrice !== null && (
                  <p className={Styles.cartCard__originalPrice}>{formatPriceForDisplay((price / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartCard;
