import { FunctionComponent, useContext } from 'react';

import Button from '@/components/Button';
import CartCard from '@/components/CartCard';
import Icon from '@/components/Icon';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Spinner from '@/components/Spinner';
import Title from '@/components/Title';

import cartImage from '@/globals/images/hero_productChoiceStep.jpg';

import { TCustomerAnswerResponse, TCustomerRoutine } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { ProcessContext } from '..';

import { TCartStep } from './interfaces';
import Styles from './styles.module.scss';

const CartStep: FunctionComponent<TCartStep> = ({ prev, products, onSubmit, isLoading }) => {
  const {
    customImages: { imageFormCart },
  } = useContext(ECommerceContext);
  const { recommendations } = useContext(ProcessContext);
  const { routines } = recommendations as TCustomerAnswerResponse;

  if (products) {
    const routinesFiltered = routines.filter((routine) => {
      return (
        routine.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined ||
        routine.sub_routines.filter((sub: TCustomerRoutine) => {
          return sub.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined;
        }).length > 0
      );
    });

    return (
      <div className={Styles.cartStep}>
        <div className={Styles.cartStep__back}>
          <div className={Styles.cartStep__back__link} onClick={prev}>
            <Icon icon='arrow-left' className={Styles.cartStep__back__icon} />
            <LocaleText>form.layout.back</LocaleText>
          </div>
        </div>
        <div className={Styles.cartStep__visual}>
          <img className={Styles.cartStep__visual__Image} src={imageFormCart ? `data:image/png;base64,${imageFormCart}` : cartImage} alt='Cart' />
        </div>

        <div className={Styles.cartStep__contentContainer}>
          <Inner type='md' className={Styles.cartStep__heading}>
            <Title className={Styles.cartStep__title} type='h2'>
              <LocaleText>page.cartStep.title</LocaleText>
            </Title>
            <Title className={Styles.cartStep__subtitle} type='h3'>
              <LocaleText>page.cartStep.subtitle</LocaleText>
            </Title>
          </Inner>

          <div className={Styles.cartStep__body}>
            {products.length === 1 ? (
              products.map((selectedProduct) => {
                for (const routine of routinesFiltered) {
                  for (const product of routine.proposed_products) {
                    if (product.externalId === selectedProduct.externalId) {
                      return <CartCard key={'cartCard-' + product.id} className={Styles.cartStep__routine__card} {...product} fullWidth />;
                    }
                  }
                  for (const subRoutine of routine.sub_routines) {
                    for (const product of subRoutine.proposed_products) {
                      if (product.externalId === selectedProduct.externalId) {
                        return <CartCard key={'cartCard-' + product.id} className={Styles.cartStep__routine__card} {...product} fullWidth />;
                      }
                    }
                  }
                }
                return null;
              })
            ) : (
              <div className={Styles.cardStep__cardContainer}>
                {products.map((selectedProduct) => {
                  for (const routine of routinesFiltered) {
                    for (const product of routine.proposed_products) {
                      if (product.externalId === selectedProduct.externalId) {
                        return <CartCard key={'cartCard-' + product.id} className={Styles.cartStep__routine__card} {...product} />;
                      }
                    }
                    for (const subRoutine of routine.sub_routines) {
                      for (const product of subRoutine.proposed_products) {
                        if (product.externalId === selectedProduct.externalId) {
                          return <CartCard key={'cartCard-' + product.id} className={Styles.cartStep__routine__card} {...product} />;
                        }
                      }
                    }
                  }
                  return null;
                })}
              </div>
            )}
          </div>

          <div className={Styles.cartStep__buttonContainer}>
            <Button rounded onClick={onSubmit} disabled={isLoading}>
              <LocaleText>page.cartStep.submit</LocaleText>
              {isLoading ? <Spinner className={Styles.cartStep__submitLoading} /> : null}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.cartStep}>
      <Spinner className={Styles.cartStep__loading} />
    </div>
  );
};

export default CartStep;
