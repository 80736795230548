import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

import { LocaleContext } from '@/providers/LocaleProvider';

import { TScoreCardProps } from './interface';
import Styles from './styles.module.scss';
import ImagePolygon from '../ImagePolygon';
import LocaleText from '../Intl/LocaleText';

const ScoreCard: FunctionComponent<TScoreCardProps> = ({ data, picture }) => {
  const { description, front_name, name, score, score_type } = data;
  const { getTranslation } = useContext(LocaleContext);

  const textRef = useRef<HTMLParagraphElement>(null);
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const toggleDescription = () => setDescriptionIsExpanded((current) => !current);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [description]);

  return (
    <div className={Styles.scoreCard__container}>
      <div className={Styles.scoreCard__summaryContainer}>
        <p className={Styles.scoreCard__name}>{name}</p>
        <div className={Styles.scoreCard__ratingContainer}>
          <p className={`${Styles.scoreCard__rating} ${Styles[getTranslation(`page.diagnosisStep.color.${score_type}`)]}`}>{score}</p>
          <p className={`${Styles[getTranslation(`page.diagnosisStep.color.${score_type}_text`)]}`}>{front_name}</p>
        </div>
      </div>

      <div className={Styles.scoreCard__pictureContainer}>
        <ImagePolygon className={Styles.scoreCard__picture} image={picture} polygons={[data]} />
      </div>

      <div className={Styles.scoreCard__textContainer}>
        <p className={`${Styles.scoreCard__text} ${descriptionIsExpanded ? Styles['scoreCard__text--expanded'] : ''}`} ref={textRef}>
          {description}
        </p>
        {isTruncated ? (
          <button className={Styles.scoreCard__seeMoreButton} onClick={toggleDescription}>
            {descriptionIsExpanded ? <LocaleText>page.diagnosisStep.seeLess</LocaleText> : <LocaleText>page.diagnosisStep.seeMore</LocaleText>}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ScoreCard;
