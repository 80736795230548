import { FunctionComponent } from 'react';

import { TAgeCardProps } from './interface';
import Styles from './styles.module.scss';
import LocaleText from '../Intl/LocaleText';

const AgeCard: FunctionComponent<TAgeCardProps> = ({ data }) => {
  const { score } = data;

  return (
    <div className={Styles.ageCard__container}>
      <div className={Styles.ageCard__summaryContainer}>
        <p className={Styles.ageCard__name}>
          <LocaleText>page.diagnosisStep.skin_age</LocaleText>
        </p>
      </div>
      <div className={Styles.ageCard__visual}>
        <div className={Styles.ageCard__visual__image__age}>
          {score} <LocaleText>page.diagnosisStep.years</LocaleText>
        </div>
      </div>

      <div className={Styles.ageCard__text}>
        <LocaleText>page.diagnosisStep.skin_age_description</LocaleText>
      </div>
    </div>
  );
};

export default AgeCard;
