import { FunctionComponent, useContext } from 'react';

import arrowLeft from '@/globals/images/arrowLeft.svg';

import { LocaleContext } from '@/providers/LocaleProvider';

import ImagePolygon from '../ImagePolygon';

import { TScoreDetailProps } from './interface';
import Styles from './styles.module.scss';

const ScoreDetail: FunctionComponent<TScoreDetailProps> = ({ data, onGoBack, picture }) => {
  const { description, front_name, name, score, score_type } = data;
  const { getTranslation } = useContext(LocaleContext);

  const ratingColorName = getTranslation(`page.diagnosisStep.color.${score_type}`);

  return (
    <div className={Styles.scoreDetail__container}>
      <div className={Styles.scoreDetail__summaryContainer}>
        <button className={Styles.scoreDetail__backButton} onClick={onGoBack}>
          <img src={arrowLeft} className={Styles.scoreDetail__backIcon} alt='' />
        </button>
        <p className={Styles.scoreDetail__name}>{name}</p>
        <p>{score}/100</p>
        <p className={`${Styles.scoreDetail__rating} ${Styles[ratingColorName]}`}>{front_name}</p>
      </div>
      <div className={Styles.scoreDetail__pictureContainer}>
        <ImagePolygon image={picture} polygons={[data]} className={Styles.scoreDetail__picture} />
      </div>
      <div className={Styles.scoreDetail__textContainer}>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ScoreDetail;
