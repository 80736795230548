import { THandleAddProductToCart, THandleRemoveProductFromCart, TManageExternalCart } from './interface';
import { addProductToShopifyCart, removeProductFromShopifyCart } from './shopify';

const handleAddProductToCart: THandleAddProductToCart = (cartUrl, id, type, quantity) => {
  switch (type) {
    case 'Shopify':
      addProductToShopifyCart(cartUrl, id, quantity);
      break;
    case 'Custom':
      break;
    default:
      break;
  }
};

const handleRemoveProductFromCart: THandleRemoveProductFromCart = (cartUrl, id, type) => {
  switch (type) {
    case 'Shopify':
      removeProductFromShopifyCart(cartUrl, id);
      break;
    case 'Custom':
      break;
    default:
      break;
  }
};

const handleExternalCart: TManageExternalCart = (cartUrl, id, active, type, quantity) => {
  /* TODO Fix this: when a product is selected in a routine and user clicks on another, it adds both products to the external cart
   * instead of removing one and adding the other */
  if (active) handleAddProductToCart(cartUrl, id, type, quantity);
  else handleRemoveProductFromCart(cartUrl, id, type);
};

export default handleExternalCart;
