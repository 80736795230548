import React from 'react';

import LocaleText from '@/components/Intl/LocaleText';
import Styles from './styles.module.scss';
import FormStyles from '../../components/FormMultiStep/styles.module.scss';

import { TPersonalizationStepFormLayout } from './interfaces';

const Layout: React.FC<TPersonalizationStepFormLayout> = ({ title, image, children }) => (
  <div className={`${Styles.personnalisationStep} ${FormStyles.multiStepForm__step}`}>
    <div className={Styles.personnalisationStep__imageContainer}>
      <img className={Styles.personnalisationStep__image} src={image} alt='' />
    </div>
    <h1 className={`title ${Styles.personnalisationStep__title}`}>
      <LocaleText>{title}</LocaleText>
    </h1>
    <div className={Styles.personnalisationStep__content}>{children}</div>
  </div>
);

export default Layout;
