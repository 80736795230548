import { adjustColorBrightness } from '@/helpers/color';
import { useGenerateECommerce } from '@/hooks/useGenerateECommerce';
import { FC, ReactNode, createContext, useEffect, useMemo } from 'react';
import { TECommerceInfo } from '@/helpers/API/requests/interfaces/customer';

interface ECommerceProviderProps {
  children: ReactNode;
}

type ECommerceContextType = TECommerceInfo & {
  token: string;
  eCommerceLoading: boolean;
  eCommerceError: string | null;
};

const DEFAULT_ECOM: ECommerceContextType = {
  availablePathologies: [],
  cguLink: '',
  color1: '',
  color2: '',
  customer: null,
  customImages: {
    imageFormAge: null,
    imageFormCart: null,
    imageFormEmail: null,
    imageFormGenre: null,
    imageFormPathology: null,
    imageFormProblem: null,
    imageFormRoutines: null,
    imageFormSensitiveSkin: null,
    imageFormSkinType: null,
    imageHome: null,
    imagePhoto: null,
  },
  description: '',
  eCommerceError: null,
  eCommerceLoading: false,
  externalCartUrl: '',
  hideAge: false,
  hideEmailRequest: true,
  hideSensitiveSkin: false,
  identifier: 'default',
  name: '',
  subtitle: '',
  title: '',
  token: '',
  type: 'Custom',
};

export const ECommerceContext = createContext<ECommerceContextType>({
  ...DEFAULT_ECOM,
});

export const ECommerceProvider: FC<ECommerceProviderProps> = ({ children }) => {
  const { eCommerceData, isLoading, token, eCommerceError } = useGenerateECommerce();

  const eCommerceInfos = useMemo(() => {
    if (eCommerceData) {
      return eCommerceData.e_commerce;
    }
    return {
      ...DEFAULT_ECOM,
    };
  }, [eCommerceData]);

  useEffect(() => {
    if (eCommerceInfos.color1 !== '') {
      document.documentElement.style.setProperty('--color-sa-action', eCommerceInfos.color1);
      document.documentElement.style.setProperty('--color-sa-action-translucent', `${eCommerceInfos.color1}80`);
      document.documentElement.style.setProperty('--color-sa-action-dark', adjustColorBrightness(eCommerceInfos.color1, -10));
      document.documentElement.style.setProperty('--color-sa-action-light', `${eCommerceInfos.color1}1A`);
    }
    if (eCommerceInfos.color2 !== '') {
      document.documentElement.style.setProperty('--color-sa-info', eCommerceInfos.color2);
    }
  }, [eCommerceInfos]);

  return (
    <ECommerceContext.Provider
      value={{
        token,
        ...eCommerceInfos,
        eCommerceLoading: isLoading,
        eCommerceError,
      }}
    >
      {children}
    </ECommerceContext.Provider>
  );
};
