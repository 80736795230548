import { FunctionComponent, useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Form, { EInputType } from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';
import Spinner from '@/components/Spinner';

import BannerImage from '@/globals/images/hero_emailStep.png';

import { LocaleContext } from '@/providers/LocaleProvider';

import Layout from './Layout';
import Styles from './styles.module.scss';

const Email: FunctionComponent = () => {
  const { getTranslation } = useContext(LocaleContext);

  const [emailValid, setEmailValid] = useState(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [sending, send] = useState<boolean>(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFocus(true);
    }, 500);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <Layout title='form.personalizationStep.email.title' image={BannerImage}>
      <div className={Styles['personnalisationStep__description']}>
        <LocaleText isHtml={true}>form.personalizationStep.email.description</LocaleText>
      </div>
      <Form.Input
        focus={focus}
        type={EInputType.EMAIL}
        name='customer_email'
        placeholder={getTranslation('form.personalizationStep.email.placeholder')}
        required
        onChange={(value) => {
          const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
          setEmailValid(regex.test(value.toString()) === true ? true : false);
        }}
        className={Styles.emailInputContainer}
      />
      <div className={Styles['personnalisationStep__footer']}>
        <div className={Styles['personnalisationStep__footerCTA']}>
          <Button
            type='submit'
            disabled={!emailValid}
            rounded
            className={`${sending ? Styles.sending : ''}`}
            onClick={() => {
              if (!sending) {
                send(true);
              }
            }}
          >
            <LocaleText>form.personalizationStep.submit</LocaleText>
            {sending ? <Spinner className={Styles['personnalisationStep__footerCTA__spinner']} /> : undefined}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Email;
