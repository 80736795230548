import { FunctionComponent, useEffect, useState, useRef, useContext } from 'react';

import LocaleText from '@/components/Intl/LocaleText';

import { ReactComponent as AddedItem } from '@/globals/images/addedItem.svg';
import { ReactComponent as AddItem } from '@/globals/images/addItem.svg';

import { PRICE_FORMAT_OPTIONS } from '@/helpers/constants';
import { formatPriceForDisplay } from '@/helpers/price';

import { LocaleContext } from '@/providers/LocaleProvider';

import Button from '../Button';

import { TRecommendationCard } from './interfaces';
import Styles from './styles.module.scss';

const RecommendationCard: FunctionComponent<TRecommendationCard> = ({
  brand,
  className,
  description,
  externalId,
  fullWidth,
  id,
  image_url,
  isActive = false,
  isVisible = true,
  name,
  price,
  reducedPrice,
  onSelect = () => {},
}) => {
  const { locale } = useContext(LocaleContext);
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const toggleDescription = () => setDescriptionIsExpanded((current) => !current);

  const descriptionRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const height = descriptionRef.current.scrollHeight;
      setIsButtonVisible(height > 42);
    }
  }, [description]);

  return (
    <div className={`${Styles.recommendationCardContainer} ${className ? ` ${className}` : ''}`} data-id={id}>
      <div
        className={`${Styles.recommendationCard} ${isActive ? ` ${Styles['recommendationCard--active']}` : ''}${
          isVisible ? ` ${Styles['recommendationCard--visible']}` : ''
        } ${fullWidth ? ` ${Styles['recommendationCard--fullWidth']}` : ''}`}
      >
        <div className={Styles.recommendationCard__body}>
          <div className={Styles.recommendationCard__visual}>
            <img src={image_url} className={Styles['recommendationCard__visual-image']} alt='card_visual' />
          </div>
          <div className={Styles.recommendationCard__content}>
            <p className={Styles.recommendationCard__title}>{name}</p>
            <p className={Styles.recommendationCard__brand}>{brand?.name}</p>
            <p
              ref={descriptionRef}
              dangerouslySetInnerHTML={{ __html: description }}
              className={`${Styles.recommendationCard__description} ${descriptionIsExpanded ? Styles.recommendationCard__descriptionExpanded : ''}`}
            ></p>
            {isButtonVisible ? (
              <button className={Styles.recommendationCard__seeMoreButton} onClick={toggleDescription}>
                {descriptionIsExpanded ? <LocaleText>page.recommendationStep.seeLess</LocaleText> : <LocaleText>page.recommendationStep.seeMore</LocaleText>}
              </button>
            ) : null}
          </div>

          <div className={`${Styles.recommendationCard__footer} ${price === null ? Styles.recommendationCard__noPriceAlignement : null}`}>
            {price !== null && (
              <div>
                {reducedPrice !== null && (
                  <p className={Styles.recommendationCard__originalPrice}>
                    {formatPriceForDisplay((price / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))}
                  </p>
                )}
                <p className={Styles.recommendationCard__finalPrice}>
                  {reducedPrice !== null
                    ? formatPriceForDisplay((reducedPrice / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))
                    : formatPriceForDisplay((price / 100).toLocaleString(locale, PRICE_FORMAT_OPTIONS))}
                </p>
              </div>
            )}

            <Button
              className={Styles.recommendationCard__button}
              onClick={() => {
                onSelect(id, externalId, !isActive, undefined);
              }}
              outline={!isActive}
            >
              {isActive ? (
                <>
                  <AddedItem />
                  <div className={`${Styles.recommendationCard__buttonText} ${Styles['recommendationCard__buttonText-active']}`}>
                    <LocaleText>page.recommendationStep.selectButton.selected</LocaleText>
                  </div>
                </>
              ) : (
                <>
                  <AddItem className={Styles.recommendationCard__button__addIcon} />
                  <div className={`${Styles.recommendationCard__buttonText} ${Styles['recommendationCard__buttonText-default']}`}>
                    <LocaleText>page.recommendationStep.selectButton.notSelected</LocaleText>
                  </div>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationCard;
