import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import checkMark from '@/globals/images/checkmark.svg';
import pictureMiniature from '@/globals/images/miniature_pictureStep.jpg';
import pictureFrame from '@/globals/images/pictureFrameBig.svg';
import { ReactComponent as RetakeArrow } from '@/globals/images/clockwiseArrow.svg';

import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import MultiStep, { TMultiStepRef } from '@/components/MultiStep';
import Popin from '@/components/Popin';
import RoundProgress from '@/components/RoundProgress';

import { ProcessContext } from '../../screens/Auth/ProcessAI';

import Styles from './styles.module.scss';
import { TPictureNonMobile } from './interfaces';
import PopinError from '@/components/PopinError';
import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';
import { pdfjs } from 'react-pdf';

const analysisItemsTotal = 8;
const analysisItemsTotalRendered = 6;
const analysisItemsTransitionDelayMs = 1000;
const analysisItemsTransitionDurationMs = 500;
const analysisItemsDurationMs = analysisItemsTransitionDelayMs + analysisItemsTransitionDurationMs;
const analysisItemsTotalDurationMs = analysisItemsDurationMs * analysisItemsTotalRendered;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PictureNonMobile: FunctionComponent<TPictureNonMobile> = ({ onSubmit, onError, goToPreviousStep }) => {
  const { sendPicture, picture, downloadPictureRoute } = useContext(ProcessContext);

  const pictureBodyRef = useRef<TMultiStepRef>(null);
  const videoInputRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);

  const [videoPermissionGiven, setVideoPermissionGiven] = useState<boolean>(false);
  const [initialDelay, setInitialDelay] = useState<number>(-1);
  const [stepTransition, setStepTransition] = useState<boolean>(false);
  const [isTransitionPrev, setIsTransitionPrev] = useState<boolean>(false);
  const [flash, setFlash] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(-1);
  const [analysisItem, setAnalysisItem] = useState<number>(0);
  const [isPicTaken, setIsPicTaken] = useState<boolean>(false);
  const [progressIsRunning, startProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [errorPopin, setErrorPopin] = useState<string | null>(null);
  const [errorCameraNotAllowedPopin, setErrorCameraNotAllowedPopin] = useState<boolean | null>(null);
  const [errorCameraNotFoundPopin, setErrorCameraNotFoundPopin] = useState<boolean | null>(null);

  const { color2: infoColor } = useContext(ECommerceContext);

  const progressDelay = useMemo(() => Math.round(Math.random() * 500) + 500, []);
  const analysisItemsRendered = useMemo(() => {
    const list = Array(analysisItemsTotal)
      .fill(0)
      .map((_, i) => i);

    while (list.length > analysisItemsTotalRendered) {
      const removeStep = Math.trunc(Math.random() * analysisItemsTotalRendered);

      if (removeStep !== analysisItemsTotalRendered) {
        const removeIndex = list.indexOf(removeStep);

        if (removeIndex !== -1) {
          list.splice(removeIndex, 1);
        }
      }
    }
    return list.map((item) => `page.pictureStep.analysisStep.list.${item}`);
  }, []);

  useEffect(() => {
    if (videoPermissionGiven) {
      setTimeout(() => {
        setInitialDelay(2);
      }, 500);
    }
  }, [videoPermissionGiven]);

  useEffect(() => {
    const nextInitialDelay = initialDelay - 1;

    if (nextInitialDelay >= 0) {
      const timeId = setTimeout(() => {
        setInitialDelay(nextInitialDelay);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
    if (initialDelay === 0) {
      takePicture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDelay]);

  const takePicture = useCallback(() => {
    const video = videoInputRef.current;
    const canvas = canvasRef.current;

    pictureBodyRef.current!.next();
    if (video && canvas) {
      setTimeout(() => {
        setCountdown(3);
      }, 500);
    }
  }, []);

  const analysisStep = useCallback(
    (index: number) => {
      startProgress(true);
      setTimeout(() => {
        setAnalysisItem(index + 1);
        if (index < analysisItemsRendered.length - 1) {
          analysisStep(index + 1);
        }
      }, analysisItemsDurationMs);
    },
    [analysisItemsRendered.length]
  );

  const resetToRetakePicture = () => {
    pictureBodyRef.current?.prev();
    setIsPicTaken(false);
    setInitialDelay(-1);
    setCountdown(-1);
    setVideoPermissionGiven(false);
  };

  useEffect(() => {
    if (progressIsRunning) {
      const timeId = setTimeout(() => {
        const _progress = Math.min(progress + Math.round(100 / ~~(analysisItemsTotalDurationMs / progressDelay)), 100);

        setProgress(_progress);
        if (_progress === 100) {
          startProgress(false);
        }
      }, progressDelay);

      return () => {
        clearTimeout(timeId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressIsRunning, progress]);

  useEffect(() => {
    if (!progressIsRunning && progress === 100 && analysisItem === analysisItemsRendered.length) {
      setTimeout(() => {
        pictureBodyRef.current!.next();
      }, analysisItemsTransitionDelayMs);
    }
  }, [analysisItem, analysisItemsRendered.length, progress, progressIsRunning]);

  useEffect(() => {
    if (picture && downloadPictureRoute) {
      setImageData(picture);
      setIsPicTaken(true);
      pictureBodyRef.current?.goToStep('photoStep');
    }
  }, [picture, downloadPictureRoute]);

  useEffect(() => {
    if (!videoPermissionGiven && !picture) {
      if (navigator.mediaDevices?.getUserMedia && videoInputRef.current !== null) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then((stream) => {
            videoInputRef.current.srcObject = stream;
            videoInputRef.current.play();
            setVideoPermissionGiven(true);
          })
          .catch((error) => {
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
              setErrorCameraNotAllowedPopin(true);
            } else if (error.name === 'NotFoundError') {
              setErrorCameraNotFoundPopin(true);
            } else {
              setErrorPopin(error.message);
              console.log(error.name);
              console.error('Something went wrong!', error);
            }
          });
      } else {
        setErrorCameraNotFoundPopin(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPermissionGiven]);

  useEffect(() => {
    const nextCountdown = countdown - 1;

    if (nextCountdown >= 0) {
      const timeId = setTimeout(() => {
        setCountdown(nextCountdown);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [countdown]);

  useEffect(() => {
    if (!countdown) {
      const video = videoInputRef.current;
      const canvas = canvasRef.current;

      if (!video || !canvas) return;

      const context = canvas.getContext('2d');

      // Obtenir les dimensions de la vidéo
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      // Réglez les dimensions du canvas pour correspondre à la résolution de la vidéo
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      if (context) {
        context.save();
        context.scale(-1, 1);
        context.drawImage(video, -videoWidth, 0, videoWidth, videoHeight);
        context.restore();
      }

      const image_data_url = canvas.toDataURL('image/jpeg');

      setImageData(image_data_url);
      setIsPicTaken(true);
      setFlash(true);
    }
  }, [countdown]);

  useEffect(() => {
    if (flash) {
      const timeId = setTimeout(() => {
        setFlash(false);
      }, 80);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [flash]);

  return (
    <div className={Styles.pictureNonMobile}>
      <Popin showClose active={Boolean(errorPopin)} onClickUnderlay={onError} onClose={onError}>
        <div className={Styles['pictureNonMobile__popin--error']}>
          <div>
            <LocaleText>global.error.subtitle</LocaleText>
          </div>
          <p>{errorPopin}</p>
          <span className={Styles.popin__close__link} onClick={onError}>
            <LocaleText>global.error.backHome</LocaleText>
          </span>
        </div>
      </Popin>

      <PopinError
        active={Boolean(errorCameraNotFoundPopin)}
        onClose={onError}
        title={'global.popinErrorCameraNotFound.title'}
        description={'global.popinErrorCameraNotFound.description'}
        backHome={'global.popinErrorCameraNotFound.backHome'}
      />

      <PopinError
        active={Boolean(errorCameraNotAllowedPopin)}
        onClose={onError}
        title={'global.popinErrorCameraNotAllowed.title'}
        description={'global.popinErrorCameraNotAllowed.description'}
        backHome={'global.popinErrorCameraNotAllowed.backHome'}
      />

      <div className={Styles.pictureNonMobile__container}>
        <Inner className={Styles.pictureNonMobile__videoContainer}>
          <div className={`${Styles.pictureNonMobile__video} ${isPicTaken ? Styles['is-picture'] : ''}`}>
            <div className={`${Styles['pictureNonMobile__video-flash']} ${flash ? Styles['is-active'] : ''}`}></div>
            {analysisItem && analysisItem > 0 ? (
              <div
                className={`${Styles['pictureNonMobile__video-scan']} ${
                  analysisItem && analysisItem > 0 && analysisItem < analysisItemsRendered.length ? Styles['is-active'] : ''
                }`}
              ></div>
            ) : (
              ''
            )}
            <div>
              <img src={pictureFrame} alt='' className={Styles['pictureNonMobile__video-mask']} />
            </div>
            <video ref={videoInputRef} className={Styles['pictureNonMobile__video-input']} autoPlay playsInline />
            <img src={imageData ?? undefined} className={Styles['pictureNonMobile__video-image']} alt='' />
            <canvas ref={canvasRef} className={Styles['pictureNonMobile__video-canvas']}></canvas>
          </div>
        </Inner>

        <div
          className={`${Styles.pictureNonMobile__footerContainer} ${stepTransition ? Styles['is-transition'] : ''} ${
            isTransitionPrev ? Styles['is-prev'] : ''
          }`}
        >
          <MultiStep
            ref={pictureBodyRef}
            delay={500}
            onPrevStep={() => {
              setIsTransitionPrev(true);
            }}
            onTransitionStart={() => {
              setTimeout(() => {
                setStepTransition(true);
              }, 50);
            }}
            onTransitionEnded={() => {
              setIsTransitionPrev(false);
              setStepTransition(false);
            }}
            onSubmitLastStep={() => {
              if (imageData) {
                onSubmit(imageData);
              }
            }}
          >
            <MultiStep.Step name={'initStep'}>
              <>
                {!stepTransition && (
                  <div className={Styles['pictureNonMobile__step-description']}>
                    <div className={Styles.pictureNonMobile__step__miniature}>
                      <img src={pictureMiniature} alt='' />
                    </div>
                    <div className={Styles.pictureNonMobile__step__hint}>
                      <LocaleText>page.pictureStep.initStep.description</LocaleText>
                    </div>
                  </div>
                )}
              </>
            </MultiStep.Step>

            <MultiStep.Step name={'photoStep'}>
              <>
                {!isPicTaken ? (
                  <div className={Styles['pictureNonMobile__step-title']}>
                    <p className={`${Styles.pictureNonMobile__countdown} ${countdown >= 0 ? Styles.isActive : ''}`}>{countdown}</p>
                  </div>
                ) : null}
                <div className={`${Styles['pictureNonMobile__step-hiddenContent']} ${isPicTaken ? Styles['is-active'] : ''}`}>
                  <div className={Styles['pictureNonMobile__step-footerCTA-alt']}>
                    <Button
                      className={`${Styles['pictureNonMobile__step-footerCTAButton']} ${Styles['pictureNonMobile__step-footerRetakeButton']}`}
                      rounded
                      outline
                      onClick={downloadPictureRoute ? goToPreviousStep : resetToRetakePicture}
                    >
                      <RetakeArrow className={Styles.pictureNonMobile__retakeArrow} />
                      {downloadPictureRoute ? (
                        <LocaleText>page.pictureStep.photoStep.return</LocaleText>
                      ) : (
                        <LocaleText>page.pictureStep.photoStep.restart</LocaleText>
                      )}
                    </Button>
                    <Button
                      className={Styles['pictureNonMobile__step-footerCTAButton']}
                      rounded
                      onClick={() => {
                        pictureBodyRef.current!.next();
                        analysisStep(0);
                        !downloadPictureRoute && videoInputRef.current.srcObject.getTracks().forEach((track: any) => track.stop());
                        videoInputRef.current.srcObject = null;
                        if (imageData) {
                          sendPicture(imageData);
                        }
                      }}
                    >
                      <img src={checkMark} alt='' />
                      <LocaleText>page.pictureStep.photoStep.cta</LocaleText>
                    </Button>
                  </div>
                </div>
              </>
            </MultiStep.Step>

            <MultiStep.Step name={'analysisStep'}>
              <div className={Styles.pictureNonMobile__analysisStep__container}>
                <RoundProgress
                  className={Styles.pictureNonMobile__analysis__progress}
                  progress={progress}
                  foregroundColor={infoColor}
                  transitionDelay={progressDelay}
                >
                  {Math.round(progress)}%
                </RoundProgress>
                <div className={Styles.pictureNonMobile__analysisStep__textContent}>
                  <LocaleText>page.pictureStep.analysisStep.title</LocaleText>
                  <div className={Styles.pictureNonMobile__analysis}>
                    <ul
                      className={Styles.pictureNonMobile__analysis__container}
                      style={{
                        transitionDuration: `${analysisItemsTransitionDurationMs}ms`,
                        right: `${Math.min(analysisItem, analysisItemsRendered.length - 1) * 100}%`,
                      }}
                    >
                      {analysisItemsRendered.map((analysisItemRendered) => (
                        <li key={analysisItemRendered} className={Styles['pictureNonMobile__analysis__container--item']}>
                          <LocaleText>{analysisItemRendered}</LocaleText>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </MultiStep.Step>

            <MultiStep.Step name={'diagnosisStep'}>
              <div className={Styles.pictureNonMobile__diagnosisStep__title}>
                <LocaleText>page.pictureStep.diagnosisStep.title</LocaleText>
              </div>
              <Button
                className={Styles.pictureNonMobile__diagnosisStep__button}
                rounded
                onClick={() => {
                  pictureBodyRef.current!.next();
                }}
              >
                <LocaleText>page.pictureStep.diagnosisStep.cta</LocaleText>
              </Button>
            </MultiStep.Step>
          </MultiStep>
        </div>
      </div>
    </div>
  );
};

export default PictureNonMobile;
