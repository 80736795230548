import { useContext } from 'react';

import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';

import BannerImage from '@/globals/images/hero_SkinTypeStep.png';

import { ECustomerSkin } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';

const Skin: React.FC<TPersonalizationStepForm> = ({ formRef }) => {
  const {
    customImages: { imageFormSkinType },
  } = useContext(ECommerceContext);

  return (
    <Layout title='form.personalizationStep.skin.title' image={imageFormSkinType ? `data:image/png;base64,${imageFormSkinType}` : BannerImage}>
      {Object.values(ECustomerSkin).map((value) => (
        <Form.RadioButton
          key={value}
          name='skin'
          value={value}
          onChanged={() => {
            formRef.current!.next();
          }}
        >
          <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
      ))}
    </Layout>
  );
};

export default Skin;
