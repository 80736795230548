import React, { useContext } from 'react';

import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';

import BannerImage from '@/globals/images/hero_sensitiveSkinStep.png';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';

const Sensibility: React.FC<TPersonalizationStepForm> = ({ formRef }) => {
  const {
    customImages: { imageFormSensitiveSkin },
  } = useContext(ECommerceContext);

  return (
    <Layout title='form.personalizationStep.sensibility.title' image={imageFormSensitiveSkin ? `data:image/png;base64,${imageFormSensitiveSkin}` : BannerImage}>
      {[true, false].map((value, i) => (
        <Form.RadioButton
          key={i}
          name='sensitive_skin'
          value={value}
          onChanged={() => {
            formRef.current!.next();
          }}
        >
          <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
      ))}
    </Layout>
  );
};

export default Sensibility;
