import { FunctionComponent, useEffect, useState } from 'react';

import Slider, { Settings } from 'react-slick';

import Inner from '@/components/Inner';
import RecommendationCard from '@/components/RecommendationCard';
import SubRoutine from '@/components/SubRoutine';

import { TRoutine } from './interfaces';
import Styles from './styles.module.scss';

const Routine: FunctionComponent<TRoutine> = ({
  index,
  rIndex,
  routine,
  isCurrentStep,
  selectedProducts,
  recommendationRoutines,
  onStepChange,
  onCardSelect,
}) => {
  const [activeCards, setActiveCards] = useState<number[]>(
    routine.proposed_products.reduce((acc, product, index) => {
      selectedProducts.forEach((selectedProduct) => {
        if (product.externalId === selectedProduct.externalId) {
          acc.push(index);
        }
      });
      return acc;
    }, [] as number[])
  );

  // Needed to enable swiping through the slider on iOS
  document.addEventListener('touchstart', () => {}, { passive: false });

  const onProductSelect = (id: number, externalId: string, active: boolean, routineIndex: number, pIndex: number) => {
    setActiveCards((prevActiveCards) => {
      if (active) {
        return [...prevActiveCards, pIndex];
      } else {
        return prevActiveCards.filter((index) => index !== pIndex);
      }
    });

    onCardSelect(id, externalId, active, routineIndex);
  };

  const sliderSettings: Settings = {
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    variableWidth: true,
  };

  useEffect(() => {
    const activeIndices = routine.proposed_products.reduce((acc, product, index) => {
      selectedProducts.forEach((selectedProduct) => {
        if (product.externalId === selectedProduct.externalId) {
          acc.push(index);
        }
      });
      return acc;
    }, [] as number[]);
    setActiveCards(activeIndices);
  }, [selectedProducts, routine.proposed_products]);

  if (routine.proposed_products.length === 1) {
    return (
      <div key={'recommendationRoutine-' + rIndex}>
        <Inner
          className={Styles.recommendationRoutine__heading}
          type={'full'}
          onClick={() => {
            isCurrentStep ? onStepChange(-1) : onStepChange(rIndex);
          }}
        >
          <p className={Styles.recommendationRoutine__title}>
            {rIndex + 1} - {routine.title}
          </p>
        </Inner>
        <div className={Styles.recommendationRoutine__body}>
          {routine?.proposed_products?.map((product, pIndex: number) => (
            <RecommendationCard
              key={'recommendationCard-' + pIndex}
              {...product}
              fullWidth
              isActive={activeCards.includes(pIndex)}
              onSelect={(id, externalId, active, i) => onProductSelect(id, externalId, active, index, pIndex)}
            />
          ))}
        </div>
        {routine.sub_routines.length > 0 && (
          <div className={Styles.recommendationRoutine__footer}>
            <div className={Styles.recommendationRoutine__subs}>
              {routine.sub_routines
                .sort((a, b) => a.position - b.position)
                .map((sub_routine, rIndex: number) => (
                  <SubRoutine
                    key={'recommendationRoutine-' + index + '-sub-' + rIndex}
                    index={index + rIndex + 1}
                    rIndex={rIndex}
                    parentIndex={index}
                    routine={sub_routine}
                    selectedProducts={selectedProducts}
                    isCurrentStep={isCurrentStep}
                    onStepChange={onStepChange}
                    onCardSelect={onCardSelect}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div key={'recommendationRoutine-' + rIndex}>
      <Inner
        className={Styles.recommendationRoutine__heading}
        type={'full'}
        onClick={() => {
          isCurrentStep ? onStepChange(-1) : onStepChange(rIndex);
        }}
      >
        <p className={Styles.recommendationRoutine__title}>
          {rIndex + 1} - {routine.title}
        </p>
      </Inner>
      <div className={Styles.recommendationRoutine__body}>
        <Inner type='full'>
          <Slider className={Styles.recommendationRoutine__slider} {...sliderSettings}>
            {routine?.proposed_products?.map((product, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isActive={activeCards.includes(pIndex)}
                onSelect={(id, externalId, active, i) => onProductSelect(id, externalId, active, index, pIndex)}
              />
            ))}
          </Slider>
        </Inner>
      </div>
      {routine.sub_routines.length > 0 && (
        <div className={Styles.recommendationRoutine__footer}>
          <div className={Styles.recommendationRoutine__subs}>
            {routine.sub_routines
              .sort((a, b) => a.position - b.position)
              .map((sub_routine, rIndex: number) => (
                <SubRoutine
                  key={'recommendationRoutine-' + index + '-sub-' + rIndex}
                  index={index + rIndex + 1}
                  rIndex={rIndex}
                  parentIndex={index}
                  routine={sub_routine}
                  selectedProducts={selectedProducts}
                  isCurrentStep={isCurrentStep}
                  onStepChange={onStepChange}
                  onCardSelect={onCardSelect}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Routine;
