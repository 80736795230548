import React from 'react';

import FormMultiStep from '@/components/FormMultiStep';

import { SexForm, AgeForm, SkinForm, SensibilityForm, PathologyForm, HealthTargetForm, EmailForm } from '@/forms/PersonalizationStep';

import { ProcessContext } from '@/screens/Auth/ProcessAI';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStep } from './interfaces';
import Styles from './styles.module.scss';
import { TFormMultiStepRef } from '@/components/FormMultiStep/interfaces';
import { TForm } from '@/components/Form';

const steps = [
  { name: 'sex', Component: SexForm },
  { name: 'age', Component: AgeForm },
  { name: 'skin', Component: SkinForm },
  { name: 'sensibility', Component: SensibilityForm },
  { name: 'pathology', Component: PathologyForm },
  { name: 'healthTarget', Component: HealthTargetForm },
  { name: 'email', Component: EmailForm },
];

const PersonalizationStep: React.FC<TPersonalizationStep> = ({ onSubmit }) => {
  const { availablePathologies, hideEmailRequest, hideSensitiveSkin } = React.useContext(ECommerceContext);
  const { diagnosis } = React.useContext(ProcessContext);
  const formMultiStep = React.useRef<TFormMultiStepRef>(null);
  const timeoutId = React.useRef<NodeJS.Timeout>();
  const diagnosisRef = React.useRef<any>(diagnosis);

  const debounceRequest = React.useCallback(
    (form: TForm) => {
      if (!timeoutId.current) {
        if (diagnosisRef.current === null) {
          timeoutId.current = setTimeout(() => {
            timeoutId.current = undefined;
            debounceRequest(form);
          }, 200);
        } else {
          onSubmit(form, diagnosisRef.current);
        }
      }
    },
    [onSubmit]
  );

  React.useEffect(() => {
    if (diagnosis !== diagnosisRef.current) {
      diagnosisRef.current = diagnosis;
    }
  }, [diagnosis]);

  let stepCount = steps.length;
  if (hideSensitiveSkin === true) stepCount--;
  if (availablePathologies.length === 0) stepCount--;
  if (hideEmailRequest === true) stepCount--;

  return (
    <div className={Styles['personnalisationStep__root']}>
      <FormMultiStep ref={formMultiStep} totalSteps={stepCount} delay={300} onSubmit={debounceRequest}>
        {steps.map(({ name, Component }) => {
          if (name === 'sensibility' && hideSensitiveSkin === true) return null;
          if (name === 'pathology' && availablePathologies.length === 0) return null;
          if (name === 'email' && hideEmailRequest) return null;
          return (
            <FormMultiStep.Step key={name} name={name}>
              <Component formRef={formMultiStep} />
            </FormMultiStep.Step>
          );
        })}
      </FormMultiStep>
    </div>
  );
};

export default PersonalizationStep;
