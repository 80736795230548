import { FC, useContext } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import AgeCard from '@/components/AgeCard';
import LocaleText from '@/components/Intl/LocaleText';
import PopinDiagnosis from '@/components/PopinDiagnosis';
import ScoreCard from '@/components/ScoreCard';
import Spinner from '@/components/Spinner';
import Title from '@/components/Title';

import { TCustomerSelfieResponse } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { ProcessContext } from '..';

import { TDiagnosisStep } from './interfaces';
import Styles from './styles.module.scss';
import Button from '@/components/Button';

const DiagnosisStep: FC<TDiagnosisStep> = ({ onSubmit }) => {
  const { hideAge } = useContext(ECommerceContext);
  const { picture, diagnosis } = useContext(ProcessContext);
  const { results } = diagnosis as TCustomerSelfieResponse;

  // Needed to enable swiping through the slider on iOS
  document.addEventListener('touchstart', () => {}, { passive: false });

  const sliderSettings: Settings = {
    arrows: false,
    centerMode: true,
    dots: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
  };

  return results.length > 0 ? (
    <div className={Styles.diagnosisStep}>
      <div className={Styles.diagnosisStep__main}>
        <div className={Styles.diagnosisStep__scoreContainer}>
          <Title className={Styles.diagnosisStep__title} type='h2'>
            <LocaleText>page.diagnosisStep.title</LocaleText>
          </Title>
          {diagnosis && (
            <Slider {...sliderSettings}>
              {results.map((card) => {
                if (card.name !== '') {
                  return <ScoreCard key={card.name} data={card} picture={picture} />;
                }
                if (card.slug === 'age' && !hideAge) {
                  return <AgeCard key={card.slug} data={card} />;
                }
                return null;
              })}
            </Slider>
          )}
        </div>
      </div>

      <div className={Styles.diagnosisStep__footer}>
        {window.screen.width > 500 ? (
          <Button className={Styles.diagnosisStep__button} onClick={onSubmit} rounded>
            <LocaleText>page.diagnosisStep.submit</LocaleText>
          </Button>
        ) : (
          <button className={Styles.diagnosisStep__button__mobile} onClick={onSubmit}>
            <LocaleText>page.diagnosisStep.submit</LocaleText>
          </button>
        )}
      </div>
    </div>
  ) : (
    <div className={Styles.diagnosisStep}>
      <Spinner className={Styles.diagnosisStep__loading} />
      <PopinDiagnosis active={true} />
    </div>
  );
};

export default DiagnosisStep;
