import { FunctionComponent, useContext } from 'react';

import Slider, { Settings } from 'react-slick';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import Title from '@/components/Title';
import Spinner from '@/components/Spinner';

import cartImage from '@/globals/images/hero_productChoiceStep.jpg';

import { TCustomerAnswerResponse, TCustomerRoutine } from '@/helpers/API/requests/interfaces/customer';

import { ProcessContext } from '..';

import Styles from './styles.module.scss';
import { TCartStep } from './interfaces';

const CartStep: FunctionComponent<TCartStep> = ({ prev, products, onSubmit }) => {
  const { recommendations } = useContext(ProcessContext);
  const { routines } = recommendations as TCustomerAnswerResponse;

  if (products) {
    let routinesFiltered = routines.filter((routine) => {
      return (
        routine.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined ||
        routine.sub_routines.filter((sub: TCustomerRoutine) => {
          return sub.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined;
        }).length > 0
      );
    });

    const sliderSettings: Settings = {
      arrows: false,
      infinite: false,
      variableWidth: true,
    };

    return (
      <div className={Styles.cartStep}>
        <div className={Styles.cartStep__back}>
          <div className={Styles.cartStep__back__link} onClick={prev}>
            <Icon icon='arrow-left' className={Styles.cartStep__back__icon} />
            <LocaleText>form.layout.back</LocaleText>
          </div>
        </div>
        <div className={Styles.cartStep__visual}>
          <img className={Styles.cartStep__visual__Image} src={cartImage} alt='Cart' />
        </div>
        <Inner type='md' className={Styles.cartStep__heading}>
          <Title className={Styles.cartStep__title} type='h2'>
            <LocaleText>page.cartStep.title</LocaleText>
          </Title>
          <Title className={Styles.cartStep__subtitle} type='h3'>
            <LocaleText>page.cartStep.subtitle</LocaleText>
          </Title>
        </Inner>

        <div className={Styles.cartStep__body}>
          <Slider className={Styles.cartStep__slider} {...sliderSettings}>
            {products.map((selectedProduct) => {
              for (const routine of routinesFiltered) {
                for (const product of routine.proposed_products) {
                  if (product.externalId === selectedProduct.externalId) {
                    return (
                      <RecommendationCard
                        key={'recommendationCard-' + product.id}
                        className={Styles.cartStep__routine__card}
                        {...product}
                        isActivable={false}
                      />
                    );
                  }
                }
                for (const subRoutine of routine.sub_routines) {
                  for (const product of subRoutine.proposed_products) {
                    if (product.externalId === selectedProduct.externalId) {
                      return (
                        <RecommendationCard
                          key={'recommendationCard-' + product.id}
                          className={Styles.cartStep__routine__card}
                          {...product}
                          isActivable={false}
                        />
                      );
                    }
                  }
                }
              }
              return null;
            })}
          </Slider>
        </div>

        <div className={Styles.cartStep__buttonContainer}>
          <Button rounded onClick={onSubmit}>
            <LocaleText>page.cartStep.submit</LocaleText>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.cartStep}>
      <Spinner className={Styles.cartStep__loading} />
    </div>
  );
};

export default CartStep;
