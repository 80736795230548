import React from 'react';

import { LocaleContext } from '@/providers/LocaleProvider';

import HTMLRender from '@/components/HTMLRender';

import { TLocaleTextProps } from './interfaces';

const LocaleText: React.FC<TLocaleTextProps> = ({ children, className, isHtml = false, params = [], plural = false }) => {
  const { getTranslation } = React.useContext(LocaleContext);

  const localeText = React.useMemo(() => getTranslation((children as string).trim(), { plural, params }), [getTranslation, children, plural, params]);

  return isHtml ? <HTMLRender>{localeText}</HTMLRender> : <p className={className}>{localeText}</p>;
};

export default LocaleText;
