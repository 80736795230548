import { ChangeEvent, useContext, useEffect } from 'react';

import { ReactComponent as Checkmark } from '@/globals/images/checkmark.svg';
import PicturePreparationImage from '@/globals/images/hero_picturePreparationStep.jpg';
import infoIcon from '@/globals/images/infoIcon.svg';
import WinkSmiley from '@/globals/images/smiley_wink.svg';

import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Title from '@/components/Title';

import { fileToBase64 } from '@/helpers/liqa';
import { shouldRefresh } from '@/helpers/refresh';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPicturePreparationStep } from './interfaces';
import Styles from './styles.module.scss';

const PicturePreparationStep: React.FC<TPicturePreparationStep> = ({ onSubmit, setPicture, startDownloadPictureRoute }) => {
  const {
    customImages: { imagePhoto },
  } = useContext(ECommerceContext);

  useEffect(() => {
    if (shouldRefresh()) {
      window.location.reload();
    }
  }, []);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageString = await fileToBase64(file);
      setPicture(imageString);
      startDownloadPictureRoute();
    }
  };

  return (
    <div className={Styles.picturePreparationStep}>
      <div className={Styles.picturePreparationStep__visual}>
        <img
          className={Styles.picturePreparationStep__visual__image}
          src={imagePhoto ? `data:image/png;base64,${imagePhoto}` : PicturePreparationImage}
          alt=''
        />
      </div>
      <Inner className={Styles.picturePreparationStep__content}>
        <div>
          <Title type='h2' className={Styles.picturePreparationStep__title}>
            <LocaleText>page.picturePreparationStep.title</LocaleText>
          </Title>
          <div className={Styles.picturePreparationStep__warning}>
            <img src={infoIcon} alt='' />
            <LocaleText className={Styles.picturePreparationStep__warningText}>page.picturePreparationStep.warning</LocaleText>
          </div>
          <div className={Styles.picturePreparationStep__description}>
            <LocaleText isHtml>page.picturePreparationStep.description</LocaleText>
            <div className={Styles.picturePreparationStep__tipSection}>
              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.firstTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText isHtml>page.picturePreparationStep.secondTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.thirdTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.forthTip</LocaleText>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.picturePreparationStep__dataPrivacy}>
          <img src={WinkSmiley} alt='' />
          <div>
            <div className={Styles.picturePreparationStep__dataPrivacy__title}>
              <LocaleText>page.picturePreparationStep.dataStatementTitle</LocaleText>
            </div>
            <div className={Styles.picturePreparationStep__dataPrivacy__text}>
              <LocaleText>page.picturePreparationStep.dataStatementText</LocaleText>
            </div>
          </div>
        </div>
        <div className={Styles.picturePreparationStep__buttonContainer}>
          <Button className={Styles.picturePreparationStep__button} onClick={onSubmit} rounded>
            <LocaleText>page.picturePreparationStep.submit</LocaleText>
          </Button>
          <input className={Styles.picturePreparationStep__fileInput} type='file' accept='image/*' onChange={handleFileChange} id='photo-upload' />
          <label htmlFor='photo-upload' className={Styles.picturePreparationStep__downloadPhotoButton}>
            <LocaleText>page.picturePreparationStep.download</LocaleText>
          </label>
        </div>
      </Inner>
    </div>
  );
};

export default PicturePreparationStep;
